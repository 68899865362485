import { render, staticRenderFns } from "./LiveScoring.vue?vue&type=template&id=811a1abc&scoped=true"
import script from "./LiveScoring.vue?vue&type=script&lang=js"
export * from "./LiveScoring.vue?vue&type=script&lang=js"
import style0 from "./LiveScoring.vue?vue&type=style&index=0&id=811a1abc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "811a1abc",
  null
  
)

export default component.exports